import { HeadingSmall } from "baseui/typography";
import React, { useEffect, useState } from "react";
import { Top } from "./Top";
import MvpMain from "../MVP/MvpMain";
import Header from "./uiComps/Head";
import Contact from "./Contact";
import Footer from "./Footer";
import CookieModal from "./uiComps/Cookie";

export type UsageData = {
  hmndid: string;
  sid: string;
  urlExtension: string;
};

const LandingPage = () => {
  const [cookies, setCookies] = useState(false);
  const [usageData, setUsageData] = React.useState<UsageData>({
    hmndid: "none",
    sid: "none",
    urlExtension: "none",
  });
  const [isDesktopDevice, setIsDesktopDevice] = useState(false);

  // Initialization
  useEffect(() => {
    // read url extension
    const url = window.location.href;
    let urlExtension = url.split("/").pop();

    if (!urlExtension) {
      urlExtension = "none";
    }
    // read local storage for id
    let hmndid = localStorage.getItem("hmndid");
    if (!hmndid) {
      // create userID
      hmndid =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
      localStorage.setItem("hmndid", hmndid);
    }
    // read session storage for session id
    let sid = sessionStorage.getItem("sid");
    if (!sid) {
      // create sessionID
      sid =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
      sid = sid + "-" + urlExtension;
      sessionStorage.setItem("sid", sid);
    }
    setUsageData({ hmndid, sid, urlExtension });
  }, []);

  useEffect(() => {
    const checkDesktop = () => {
      // User Agent Check (looking for common desktop browsers)
      const desktopRegex = /Windows NT|Macintosh|Linux x86_64/i;
      const uaIsDesktop = desktopRegex.test(navigator.userAgent);

      // Screen Size Check (typical desktop sizes are larger)
      const screenIsLarge =
        window.innerWidth >= 1024 && window.innerHeight >= 768;

      // Pointer Capability Check (desktops typically use mouse)
      const hasMousePointer = window.matchMedia("(pointer:fine)").matches;

      // Combine checks with OR logic
      setIsDesktopDevice(uaIsDesktop || screenIsLarge || hasMousePointer);
    };

    checkDesktop();
    window.addEventListener("resize", checkDesktop);

    return () => window.removeEventListener("resize", checkDesktop);
  }, []);

  const center = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  useEffect(() => {
    if (localStorage.getItem("cookie-24-10") === "true") {
      setCookies(true);
    }
  }, []);

  // Render
  // Basicaly needs to be made for phone screen minWidth 360px
  // in some sense it is a mobile app
  // no clue how to make it for desktop
  // maybe just create an sign that says "please use on mobile"

  const qrCodeComponent = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          marginBottom: "60px",
        }}
      >
        <HeadingSmall color={"#963484"} margin={0} marginBottom={"40px"}>
          Atsidarykite telefonu nuskenuodami QR kodą arba adresu{" "}
          <span
            style={{
              color: "#54BCE9",
              fontWeight: "bold",
            }}
          >
            armantinka.lt
          </span>
        </HeadingSmall>
        <img
          src="/desktop-qr-code.png"
          alt="QR Code to armantinka.lt"
          style={{ width: "256px", height: "256px" }}
        />
      </div>
    );
  };

  return (
    <div style={{ ...center, flexDirection: "column", width: "100vw" }}>
      {!cookies && <CookieModal />}
      <Header />

      <div style={{ ...center, flexDirection: "column", width: "360px" }}>
        {/* 
            Top page segment continuation from posters TBD
            */}
        {/* flexbox for top page */}
        {!isDesktopDevice && <Top />}
        {/*
            Main aplication segment for now slickinput.tsx
            */}
        {!isDesktopDevice && <div style={{ height: "100px" }} />}
        {isDesktopDevice ? (
          qrCodeComponent()
        ) : (
          <MvpMain usageData={usageData} />
        )}
        {/*
            customer reviews
            I have a few customers that will write reviews
            */}
        {/* <Reviews /> */}
        <div style={{ height: "100px" }} />
        {/*
            contact us
            serverless function to send email
            */}
        {/* {!isDesktopDevice && <Contact />} */}
      </div>
      {/*
        footer + about us
      */}
      <Footer />
    </div>
  );
};

export default LandingPage;
