import React from "react";
import "./animationScoras.css";
import {
  HeadingMedium,
  HeadingXSmall,
  LabelLarge,
  LabelMedium,
  ParagraphLarge,
} from "baseui/typography";
import { Button, KIND, SHAPE } from "baseui/button";
const scoreSwitch = (score: string) => {
  switch (score) {
    case "Awful fit":
      return { score: 1, offset: 120, message: "Ieškime toliau..." };
    case "Not worth it":
      return { score: 2, offset: 90, message: "Neverta..." };
    case "Fine":
      return { score: 3, offset: 60, message: "Neblogai!" };
    case "Good fit":
      return { score: 4, offset: 30, message: "Gerai tinka!" };
    case "Great fit":
      return { score: 4.5, offset: 15, message: "VAU! Kaip tik!" };
    case "Perfect fit":
      return { score: 5, offset: 0, message: "TOBULA - nepasigailėsi!" };
    default:
      return { score: 0, offset: 150, message: "Ups.. Kažkas netaip" };
  }
};
// const emoji = () => {
//   const emojis = ["⭐️"];
//   const randomIndex = Math.floor(Math.random() * 3);
//   return Array(5).fill(emojis[randomIndex]).join("");
// };

const Bananai = (props: { score: number; offset: number; message: string }) => {
  const contentRef = React.useRef<HTMLDivElement>(null);
  const labelRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    // if (labelRef.current) {
    //   const labelElement = labelRef.current;
    //   if (labelElement.style) {
    //     labelElement.style.opacity = `1`;
    //   }
    // }

    if (contentRef.current) {
      const contentElement = contentRef.current;
      if (contentElement.style) {
        contentElement.style.width = `${props.offset}px`;
      }
    }
  }, []);

  return (
    <div
      style={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <div ref={labelRef} className="Labelis">
        <HeadingMedium margin={"10px"} color={"#963484"}>
          {props.message}
        </HeadingMedium>
      </div>
      <div
        style={{
          width: "150px",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <div
          ref={contentRef}
          className="scoras"
          style={{
            height: "26px",
            position: "absolute",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
        ></div>
        <div style={{ textAlign: "center", fontSize: "26px" }}>
          {"⭐️⭐️⭐️⭐️⭐️"}
        </div>
      </div>
      <div className="Labelis">
        <ParagraphLarge margin={0}> {props.score} / 5 balai </ParagraphLarge>
      </div>
    </div>
  );
};

const ContentDisplay = (props: { trsContent: string }) => {
  const [expandContent, setExpandContent] = React.useState(false);
  let content = props.trsContent;

  return (
    <div>
      <div
        style={{
          textAlign: "justify",
          maxHeight: "422px",
          overflow: "scroll",
          width: "100%",
        }}
      >
        {expandContent && content}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
          width: "100%",
        }}
      >
        <Button
          shape={SHAPE.pill}
          kind={KIND.secondary}
          overrides={{
            BaseButton: {
              style: {
                width: "100%",
                backgroundColor: "#963484",
                color: "white",
              },
            },
          }}
          onClick={() => setExpandContent(!expandContent)}
        >
          {expandContent ? (
            <LabelLarge color={"white"}>Suskleisti</LabelLarge>
          ) : (
            <LabelLarge color={"white"}>Skaityti daugiau</LabelLarge>
          )}
        </Button>
      </div>
    </div>
  );
};

const Scoras = (props: { score: string; trsContent: string }) => {
  let scoreAndWidth = { score: 0, offset: 150, message: "Ups.. Kažkas netaip" };
  scoreAndWidth = scoreSwitch(props.score);

  return (
    <>
      <Bananai
        score={scoreAndWidth.score}
        offset={scoreAndWidth.offset}
        message={scoreAndWidth.message}
      />
      <div
        className="Labelis"
        style={{
          textAlign: "justify",
          marginTop: "30px",
          width: "100%",
        }}
      >
        <ContentDisplay trsContent={props.trsContent} />
      </div>
    </>
  );
};

export default Scoras;
