import { HeadingMedium, LabelMedium } from "baseui/typography";
import { StyledLink } from "baseui/link";

import React from "react";

const Footer = () => {
  return (
    <div
      style={{
        width: "100%",
        background: "rgba(217,217,217,1)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "40px",
        }}
      >
        <HeadingMedium>#ArManTinka</HeadingMedium>
        <LabelMedium style={{ textAlign: "justify", maxWidth: "370px" }}>
          Šis puslapis yra ArManTinka kelionės pradžia. Mūsų tikslas sukurti
          Jums skirtą įrankį, kuris taptų Jūsų asmeniniu dizineriu - pasiekiamu
          keliais telefono paspaudimais.
        </LabelMedium>
        <br />
        <LabelMedium margin={"40px"}>● ● ●</LabelMedium>
        <br />
        <LabelMedium style={{ textAlign: "center", maxWidth: "370px" }}>
          © 2024 ArManTinka. Visos teisės saugomos.{" "}
          <StyledLink href="/privatumo-politika">Privatumo politika</StyledLink>
        </LabelMedium>
        <div style={{ height: "40px" }} />
      </div>
    </div>
  );
};

export default Footer;
