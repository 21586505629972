import React from "react";
import Uploader from "./Uploader";
import SelectClothing from "./uiComps/SelectClothing";
import AnalysisOutput from "./Output";
import { HeadingLarge } from "baseui/typography";
import { UsageData } from "../landing/LandingPage";

export type analysisData = {
  TrsContent: string;
  score: string;
};

const MvpMain = (props: { usageData: UsageData }) => {
  const [displayImage, setDisplayImage] = React.useState<File | null>(null);
  const [errorMsg, setErrorMsg] = React.useState<string | null>(null);
  const [analysisData, setAnalysisData] = React.useState<analysisData>({
    TrsContent: "",
    score: "",
  });
  const [clothingValue, setClothingValue] = React.useState({
    id: "none",
    label: "none",
  });

  const resetContent = () => {
    setDisplayImage(null);
    setErrorMsg(null);
    setClothingValue({ id: "none", label: "none" });
    setAnalysisData({
      TrsContent: "",
      score: "",
    });
  };

  return (
    <div>
      <div
        style={{
          height: "700px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {analysisData.TrsContent == "" && (
          <SelectClothing
            errorMsg={errorMsg}
            setErrorMsg={setErrorMsg}
            clothingValue={clothingValue}
            setClothingValue={setClothingValue}
          />
        )}
        <div>
          {/* clothing image */}
          {/* 1 screen upload button */}
          {analysisData.TrsContent == "" && (
            <>
              <HeadingLarge
                style={{ marginBottom: 15, marginTop: 15, color: "#963484" }}
              >
                Įkelkite nuotrauką:
              </HeadingLarge>
              <Uploader
                errorMsg={errorMsg}
                setErrorMsg={setErrorMsg}
                clothingValue={clothingValue}
                setDisplayImage={setDisplayImage}
                displayImage={displayImage}
                setAnalysisData={setAnalysisData}
                usageData={props.usageData}
              />
            </>
          )}
          {/* 2 screen confirm screen + loading */}
          {/* 3 screen output screen */}
          {analysisData.TrsContent != "" && displayImage && (
            <AnalysisOutput
              analysisData={analysisData}
              displayImage={displayImage}
              resetContent={resetContent}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MvpMain;
