import { analysisData } from "../MVP/MvpMain";

const Faker = () : Promise<analysisData> => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve({
                
                    "TrsContent": "\n\n1Megztinis gerai priglunda prie kūno. Jis patogiai apglėbia liemenį be jokių matomų įtempimo linijų, todėl nėra nei per daug aptemptas, nei per daug laisvas. Pečių siūlės tinkamai priglunda prie pečių krašto, o rankovių ilgis yra tinkamas - jos baigiasi ties alkūne, o tai tinka trumpų rankovių modeliui. Apskritai atrodo, kad megztinis papildo kūno formas. Verta įsigyti, jei esate patenkinti stiliumi ir išvaizda.n\nMegztinis gerai priglunda prie kūno. Jis patogiai apglėbia liemenį be jokių matomų įtempimo linijų, todėl nėra nei per daug aptemptas, nei per daug laisvas. Pečių siūlės tinkamai priglunda prie pečių krašto, o rankovių ilgis yra tinkamas - jos baigiasi ties alkūne, o tai tinka trumpų rankovių modeliui. Apskritai atrodo, kad megztinis papildo kūno formas. Verta įsigyti, jei esate patenkinti stiliumi ir išvaizda.n\nMegztinis gerai priglunda prie kūno. Jis patogiai apglėbia liemenį be jokių matomų įtempimo linijų, todėl nėra nei per daug aptemptas, nei per daug laisvas. Pečių siūlės tinkamai priglunda prie pečių krašto, o rankovių ilgis yra tinkamas - jos baigiasi ties alkūne, o tai tinka trumpų rankovių modeliui. Apskritai atrodo, kad megztinis papildo kūno formas. Verta įsigyti, jei esate patenkinti stiliumi ir išvaizda.",
                    "score": "Good fit"
                
            });
        }
        , 1000);
    }
    );
}

const alpha_v0_2_1_processing = async (
    clothingValue: { id: string; label: string },
    parsedImageFile: File | null,
    sid: string,
    uid: string,
    qr: string
): Promise<any> => {
    const formData = new FormData();
    formData.append("userId", "testUser");
    if (clothingValue.id === "none" || !clothingValue.id) return Promise.reject("Invalid clothing value ID");
    if (!parsedImageFile) return Promise.reject("No parsed image file provided");
    formData.append("clothingType", clothingValue.id);
    formData.append("imageFile", parsedImageFile as Blob);
    formData.append("sessionId", sid);
    formData.append("userId", uid);
    formData.append("qr", qr);

    
    try {
        const response = await fetch(
            "https://userimageupload-cmzbf3xypq-uc.a.run.app",
            {
                method: "POST",
                body: formData,
            }
        );
        if (!response.ok) {
            return Promise.reject("Failed to fetch data");
        }
        const data = await response.json();
        return {TrsContent: data["feedback"], score: data["score"]};
    } catch (error) {
        return Promise.reject(error);
    }
}

export { alpha_v0_2_1_processing, Faker };
