import React from "react";
import elipse from "./uiComps/Ellipse 47.svg";
import FilmImage from "./uiComps/FilmImage";
import { Button, KIND, SHAPE } from "baseui/button";
import { analysisData } from "./MvpMain";
import { Spinner } from "baseui/spinner";
import { FileUploader } from "baseui/file-uploader";
import { ALLOWED_FILE_FORMATS } from "../const";
import { LabelLarge, LabelMedium } from "baseui/typography";
import Resizer from "react-image-file-resizer";
import "./uiComps/animationScoras.css";
import { alpha_v0_2_1_processing, Faker } from "../services/endpoints";
import { UsageData } from "../landing/LandingPage";

const parseImageFile = (imageFile: File) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      imageFile,
      360,
      700,
      "PNG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "blob"
    );
  });
const SubmitButtonGroup = (props: {
  ChangeImage: () => void;
  submitImage: () => void;
}) => {
  const ButtonOverrides1 = {
    backgroundColor: "#963484",
    width: "200px",
    color: "white",
    border: "none",
    paddingLeft: "20px",
    paddingRight: "20px",
  };
  const ButtonOverrides2 = {
    backgroundColor: "#54BCE9",
    color: "white",
    border: "none",
    paddingLeft: "20px",
    paddingRight: "20px",
  };
  return (
    <div
      style={{
        display: "flex",
        paddingTop: "20px",
        height: "130px",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        zIndex: 0,
      }}
    >
      <Button
        shape={SHAPE.pill}
        overrides={{
          BaseButton: {
            style: ButtonOverrides1,
          },
        }}
        onClick={props.submitImage}
      >
        <LabelLarge color={"white"}>PIRMYN</LabelLarge>
      </Button>
      <Button
        kind={KIND.secondary}
        shape={SHAPE.pill}
        overrides={{
          BaseButton: {
            style: ButtonOverrides2,
          },
        }}
        onClick={props.ChangeImage}
      >
        <LabelLarge color={"white"}>Pakeisti nuotrauką</LabelLarge>
      </Button>
    </div>
  );
};

const ImageUploader = (props: {
  setDisplayImage: (e: File) => void;
  setParsedImageFile: (e: File) => void;
}) => {
  const [loadingImage, setLoadingImage] = React.useState(false);
  const buttonOverrides = ({
    children,
    ...props
  }: {
    children: React.ReactNode;
  }) => (
    <>
      <Button
        isLoading={loadingImage}
        shape={SHAPE.square}
        {...props}
        overrides={{
          BaseButton: {
            style: {
              height: "50px",
              backgroundColor: "#963484",
              color: "white",
              border: "none",
              paddingLeft: "20px",
              paddingRight: "20px",
            },
          },
        }}
      >
        <div>
          <LabelLarge color={"white"}>Fotoaparatas</LabelLarge>
        </div>
        <div style={{ width: "30px", color: "", marginLeft: "10px" }}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              fill="#ffffff"
              d="M149.1 64.8L138.7 96 64 96C28.7 96 0 124.7 0 160L0 416c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-256c0-35.3-28.7-64-64-64l-74.7 0L362.9 64.8C356.4 45.2 338.1 32 317.4 32L194.6 32c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"
            />
          </svg>
        </div>
      </Button>
    </>
  );

  return (
    <div>
      <FileUploader
        onDrop={async (acceptedFiles, rejectedFiles) => {
          setLoadingImage(true);
          if (acceptedFiles.length === 0) {
            // TODO toaster
            alert("Netinkamas failo formatas");
            setLoadingImage(false);
            return;
          }
          const fileExtension = acceptedFiles[0].name.split(".").pop();
          if (!fileExtension) {
            // TODO toaster
            alert("Netinkamas failo formatas");
            setLoadingImage(false);
            return;
          }
          if (ALLOWED_FILE_FORMATS.indexOf(fileExtension) === -1) {
            // TODO toaster
            alert("Netinkamas failo formatas");
            setLoadingImage(false);
            return;
          }
          // TODO: check file

          // TODO: add file resize
          props.setDisplayImage(acceptedFiles[0]);
          const resizedFile = await parseImageFile(acceptedFiles[0]);
          props.setParsedImageFile(resizedFile as File);
          setLoadingImage(false);
        }}
        overrides={{
          FileDragAndDrop: {
            style: {
              backgroundColor: "RGBA(255,255,255,0.5)",
              border: "none",
            },
          },
          ContentMessage: {
            component: () => <div style={{ width: "40px", color: "" }}></div>,
          },
          ButtonComponent: {
            component: buttonOverrides,
          },
        }}
      />
    </div>
  );
};

const Uploader = (props: {
  errorMsg: string | null;
  setErrorMsg: (e: string | null) => void;
  clothingValue: { id: string; label: string };
  displayImage: File | null;
  setDisplayImage: (e: File | null) => void;
  setAnalysisData: (e: analysisData) => void;
  usageData: UsageData;
}) => {
  const [parsedImageFile, setParsedImageFile] = React.useState<File | null>(
    null
  );
  const [loading, setLoading] = React.useState(false);

  const submitImage = async () => {
    if (props.clothingValue.id === "none") {
      props.setErrorMsg("Pasirinkite drabužį viršuje");
      return;
    }
    setLoading(true);
    // send image to server
    // get back analysis data
    try {
      const data = await alpha_v0_2_1_processing(
        props.clothingValue,
        parsedImageFile,
        props.usageData.sid,
        props.usageData.hmndid,
        props.usageData.urlExtension
      );
      // const data = await Faker();

      if (data != null) {
        if (data.TrsContent != null && data.score != null) {
          setLoading(false);
          props.setAnalysisData(data);
        }
      }
    } catch (error) {
      alert("Kažkas nesuveikė, pabandykite dar kartą");
      setLoading(false);
      props.setDisplayImage(null);
    }
  };

  return (
    <div
      style={{
        width: "334px", // 340 adjusted for border
        height: "490px",
        border: "2px dashed #963484",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
      }}
    >
      <div style={{ position: "absolute" }}>
        {!props.displayImage && (
          <FilmImage selectedId={props.clothingValue.id} />
        )}
        {props.displayImage && (
          <div
            style={{
              width: "334px",
              height: "490px",
              backgroundImage: `url(${URL.createObjectURL(
                props.displayImage
              )})`,
              backgroundColor: "white",
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "10px",
            }}
          ></div>
        )}
      </div>
      {!props.displayImage && (
        <div
          style={{
            width: "334px",
            height: "490px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 0,
          }}
        >
          <ImageUploader
            setDisplayImage={props.setDisplayImage}
            setParsedImageFile={setParsedImageFile}
          ></ImageUploader>
        </div>
      )}
      {props.displayImage && (
        <div
          className="appear"
          style={{
            width: "334px",
            height: "490px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            zIndex: 0,
          }}
        >
          {loading && <Spinner $color={"#963484"}></Spinner>}
          {props.errorMsg && (
            <div
              style={{
                width: "100%",
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                fontSize: "20px",
                color: "red",
                textAlign: "center",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              {props.errorMsg}
            </div>
          )}
          {!loading && (
            <SubmitButtonGroup
              ChangeImage={() => props.setDisplayImage(null)}
              submitImage={submitImage}
            ></SubmitButtonGroup>
          )}
        </div>
      )}

      {/* <img
        src={elipse}
        alt="elipse"
        style={{
          width: "360px",
          height: "500px",
          overflow: "hidden",
          objectFit: "cover",
        }}
      ></img> */}
    </div>
  );
};

export default Uploader;
