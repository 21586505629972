import React from "react";
import { Card, StyledBody, StyledThumbnail } from "baseui/card";
import { HeadingSmall } from "baseui/typography";

import gr1 from "./uiComps/gr1.svg";
import gr2 from "./uiComps/gr2.svg";
import gr3 from "./uiComps/gr3.svg";

export const Top = () => {
  const spacing = "20px";
  return (
    <div>
      <Card
        overrides={{
          Root: {
            style: { backgroundColor: "#963484", marginBottom: spacing },
          },
        }}
      >
        <StyledThumbnail src={gr1} style={{ border: "0" }} />
        <StyledBody>
          <HeadingSmall color={"white"} marginTop={"0"}>
            Pasirink naują drabužį
          </HeadingSmall>
        </StyledBody>
      </Card>
      <Card
        overrides={{
          Root: {
            style: { backgroundColor: "#963484", marginBottom: spacing },
          },
        }}
      >
        <StyledThumbnail src={gr3} style={{ border: "0" }} />
        <StyledBody>
          <HeadingSmall color={"white"} marginTop={"0"}>
            Nusifotografuok veidrodyje
          </HeadingSmall>
        </StyledBody>
      </Card>
      <Card
        overrides={{
          Root: {
            style: { backgroundColor: "#963484", marginBottom: spacing },
          },
        }}
      >
        <StyledThumbnail src={gr2} style={{ border: "0" }} />
        <StyledBody>
          <HeadingSmall color={"white"} marginTop={"0"}>
            Iškart padėsime apsispręsti
          </HeadingSmall>
        </StyledBody>
      </Card>
    </div>
  );
};
