import { Card } from "baseui/card";
import React from "react";
import { analysisData } from "./MvpMain";
import Scoras from "./uiComps/Scoras";
import { Button, SHAPE } from "baseui/button";
import { LabelLarge } from "baseui/typography";

const AnalysisOutput = (props: {
  analysisData: analysisData;
  displayImage: File;
  resetContent: () => void;
}) => {
  const img = new Image();
  img.src = URL.createObjectURL(props.displayImage);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {props.displayImage && (
        <div
          style={{
            position: "absolute",
            width: "360px",
            height: "700px",
            // objectFit: "cover",
            backgroundImage: `url(${img.src})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            zIndex: -10,
          }}
        />
      )}
      <div
        style={{
          width: "360px",
          height: "720px",
          zIndex: 10,
          display: "flex",
          flexDirection: "column",
          justifyContent: "end",
        }}
      >
        <Card>
          <div
            style={{
              // height: "360px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {props.analysisData && (
              <Scoras
                score={props.analysisData.score}
                trsContent={props.analysisData.TrsContent}
              />
            )}
          </div>
          <div>
            <Button
              shape={SHAPE.pill}
              onClick={props.resetContent}
              overrides={{
                BaseButton: {
                  style: {
                    marginTop: "20px",
                    width: "100%",
                    backgroundColor: "#60AFFF",
                    color: "white",
                  },
                },
              }}
            >
              <LabelLarge color={"white"}>Kitas drabužis</LabelLarge>
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
              fontSize: "12px",
              color: "gray",
            }}
          >
            versija: alpha v0.2.1
          </div>
        </Card>
      </div>
    </div>
  );
};

export default AnalysisOutput;
