import React, { useState } from "react";
import { Modal } from "baseui/modal";
import { Button } from "baseui/button";

const CookieModal = () => {
  const [isOpen, setIsOpen] = useState(true);

  const handleAccept = () => {
    localStorage.setItem("cookie-24-10", "true");
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      closeable={false}
      onClose={() => setIsOpen(false)}
      overrides={{
        Root: {
          style: {
            zIndex: 9999,
          },
        },
      }}
    >
      <div style={{ padding: "20px" }}>
        <h2>Slapukų ir privatumo politika</h2>
        <p>
          Mes naudojame slapukus ir naudojame jūsų duomenis, kad pagerintume jūsų patirtį mūsų
          svetainėje. Spustelėję „Sutinku“, jūs sutinkate su mūsų{" "}
          <a href="/privatumo-politika">Privatumo politika ir svetainės naudojimo taisyklėmis</a>.
        </p>
        <div  style={{display:"flex", justifyContent:"center", width:"100%"}}>
              <Button onClick={handleAccept}>Sutinku</Button>
        </div>
       
      </div>
    </Modal>
  );
};

export default CookieModal;
