import React from "react";

const FilmImage = (props: { selectedId: string }) => {
  const tShirt = (
    <>
      <path
        d="M149.5 141H216L229 169.193L216 173.892V271H151.41C146.835 271 143.192 267.17 143.42 262.6L149.5 141Z"
        fill="#CF99C5"
      />
      <path
        d="M215.66 172.951L215 173.19V173.892V270H151.41C147.407 270 144.219 266.649 144.419 262.65L150.451 142H215.36L227.636 168.623L215.66 172.951Z"
        stroke="#996690"
        stroke-opacity="0.5"
        stroke-width="2"
      />
    </>
  );
  const trousers = (
    <>
      <path
        d="M152 263.5H214.213L232.267 369.5L197.267 374L183.713 293.5V379H152.542C147.997 379 144.366 375.219 144.548 370.678L148.5 272.5L152 263.5Z"
        fill="#CF99C5"
      />
      <path
        d="M182.713 293.5V378H152.542C148.565 378 145.388 374.692 145.548 370.718L149.492 272.707L152.684 264.5H213.369L231.106 368.641L198.093 372.886L184.7 293.334L182.713 293.5Z"
        stroke="#996690"
        stroke-opacity="0.5"
        stroke-width="2"
      />
    </>
  );
  const dress = (
    <>
      <path
        d="M149 141L182.221 167.654L202.5 138H209.829C213.44 138 216.603 140.419 217.55 143.903L224 167.654L215.946 177.5V241.5L229 290L234 337.5L182.221 327L147.204 311.092C143.854 309.569 141.974 305.958 142.649 302.341L154 241.5L149 141Z"
        fill="#CF99C5"
      />
      <path
        d="M150.109 143.172L181.596 168.434L182.438 169.109L183.047 168.218L203.028 139H209.829C212.989 139 215.756 141.116 216.585 144.165L222.9 167.419L215.172 176.867L214.946 177.143V177.5V241.5V241.632L214.981 241.76L228.014 290.183L232.863 336.249L182.532 326.043L147.618 310.181C144.687 308.849 143.042 305.689 143.632 302.524L154.983 241.683L155.005 241.568L154.999 241.45L150.109 143.172Z"
        stroke="#996690"
        stroke-opacity="0.5"
        stroke-width="2"
      />
    </>
  );

  const shorts = (
    <>
      <path
        d="M151 258H216.5L224.5 312.5L187 318L183.713 293.5V318H154.045C149.419 318 145.758 314.089 146.062 309.474L148.5 272.5L151 258Z"
        fill="#CF99C5"
      />
      <path
        d="M215.636 259L223.365 311.656L187.857 316.864L184.705 293.367L182.713 293.5V317H154.045C149.998 317 146.794 313.578 147.06 309.539L149.494 272.618L151.842 259H215.636Z"
        stroke="#996690"
        stroke-opacity="0.5"
        stroke-width="2"
      />
    </>
  );

  const jacket1 = (
    <>
      <path
        d="M178.5 169.5L197.5 144L205 136H211.79L217.268 140.405C220.183 142.75 222.341 145.902 223.471 149.467L246 220.5L221.737 230.5L217.5 201L221.737 266H194.548H166.08C160.928 266 157.119 261.201 158.289 256.183L178.5 169.5Z"
        fill="#CF99C5"
      />
      <path
        d="M244.769 219.926L222.544 229.086L218.49 200.858L216.503 201.065L220.67 265H194.548H166.08C161.572 265 158.24 260.801 159.263 256.411L179.427 169.93L198.269 144.643L205.434 137H211.438L216.641 141.185C219.402 143.405 221.447 146.392 222.518 149.77L244.769 219.926Z"
        stroke="#996690"
        stroke-opacity="0.5"
        stroke-width="2"
      />
    </>
  );

  const jacket2 = (
    <>
      <path
        d="M137 178.5L167 152.5L176 170.5L192.5 269H146.5L153.499 173.524L137 178.5Z"
        fill="#CF99C5"
      />
      <path
        d="M175.039 170.813L191.319 268H147.576L154.496 173.598L154.603 172.147L153.21 172.567L141.19 176.192L166.682 154.099L175.039 170.813Z"
        stroke="#996690"
        stroke-opacity="0.5"
        stroke-width="2"
      />
    </>
  );
  const jacket3 = (
    <>
      <path
        d="M164 120L185 140.5L143.761 177.746C140.758 180.459 136.202 180.501 133.149 177.846L126.3 171.889C122.865 168.901 122.612 163.653 125.745 160.349L164 120Z"
        fill="#CF99C5"
      />
      <path
        d="M126.471 161.037L164.028 121.424L183.539 140.472L143.091 177.004C140.463 179.378 136.477 179.415 133.805 177.091L126.957 171.135C123.951 168.52 123.73 163.928 126.471 161.037Z"
        stroke="#996690"
        stroke-opacity="0.5"
        stroke-width="2"
      />
    </>
  );

  const coat1 = (
    <>
      <path
        d="M186.5 147.5L194.548 141L204.827 125L211.79 129.5V136L217.442 140.83C220.245 143.225 222.29 146.383 223.329 149.92L247 230.5H221.737L216.1 200.5L226.5 312.5H200.5H189.316C184.776 312.5 181.146 308.727 181.322 304.19L186.5 170.5V147.5Z"
        fill="#CF99C5"
      />
      <path
        d="M215.105 200.592L225.403 311.5H200.5H189.316C185.344 311.5 182.167 308.198 182.321 304.229L187.499 170.539L187.5 170.519V170.5V147.978L195.176 141.778L195.302 141.676L195.389 141.541L205.126 126.384L210.79 130.044V136V136.461L211.14 136.76L216.793 141.591C219.448 143.859 221.385 146.851 222.37 150.202L245.664 229.5H222.567L217.083 200.315L215.105 200.592Z"
        stroke="#996690"
        stroke-opacity="0.5"
        stroke-width="2"
      />
    </>
  );
  const coat2 = (
    <>
      <path
        d="M137 178.5L172.415 142L179.5 149L176.5 239.5L172.415 309L140.5 315.5L147.816 258.776L153.499 173.524L137 178.5Z"
        fill="#CF99C5"
      />
      <path
        d="M171.462 308.174L141.671 314.241L148.808 258.904L148.812 258.874L148.814 258.843L154.497 173.591L154.593 172.15L153.21 172.567L140.402 176.43L172.43 143.421L178.486 149.404L175.501 239.454L171.462 308.174Z"
        stroke="#996690"
        stroke-opacity="0.5"
        stroke-width="2"
      />
    </>
  );
  const coat3 = (
    <>
      <path
        d="M164.074 120.516L182.574 136.516L143.774 176.997C140.821 180.078 135.969 180.299 132.749 177.498L126.342 171.926C122.891 168.924 122.654 163.646 125.822 160.348L164.074 120.516Z"
        fill="#CF99C5"
      />
      <path
        d="M126.543 161.041L164.138 121.893L181.124 136.584L143.053 176.305C140.468 179.001 136.223 179.194 133.405 176.743L126.998 171.171C123.979 168.545 123.771 163.927 126.543 161.041Z"
        stroke="#996690"
        stroke-opacity="0.5"
        stroke-width="2"
      />
    </>
  );
  const shirt1 = (
    <>
      <path
        d="M181.5 144.5L194.548 141L204.827 125L211.79 129.5V136L214.124 137.173C218.967 139.608 222.54 143.995 223.943 149.23L240.5 211L221.737 230.5L216.1 200.5V266H194.548H180.557C175.927 266 172.264 262.082 172.575 257.462L178.5 169.5L181.5 144.5Z"
        fill="#CF99C5"
      />
      <path
        d="M239.388 210.714L222.362 228.409L217.083 200.315L215.1 200.5V265H194.548H180.557C176.506 265 173.301 261.572 173.573 257.53L179.496 169.593L182.412 145.291L194.807 141.966L195.18 141.866L195.389 141.541L205.126 126.384L210.79 130.044V136V136.617L211.341 136.893L213.675 138.067C218.263 140.373 221.648 144.529 222.977 149.489L239.388 210.714Z"
        stroke="#996690"
        stroke-opacity="0.5"
        stroke-width="2"
      />
    </>
  );
  const shirt2 = (
    <>
      <path
        d="M137 178.5L172.415 142L179.5 149L172.415 233.252V265.923H147.816V258.776L153.499 173.524L137 178.5Z"
        fill="#CF99C5"
      />
      <path
        d="M153.21 172.567L140.402 176.43L172.43 143.421L178.464 149.382L171.418 233.168L171.415 233.21V233.252V264.923H148.816V258.81L154.497 173.591L154.593 172.15L153.21 172.567Z"
        stroke="#996690"
        stroke-opacity="0.5"
        stroke-width="2"
      />
    </>
  );
  const shirt3 = (
    <>
      <path
        d="M164.074 120.516L182.574 136.516L143.774 176.997C140.821 180.078 135.969 180.299 132.749 177.498L126.342 171.926C122.891 168.924 122.654 163.646 125.822 160.348L164.074 120.516Z"
        fill="#CF99C5"
      />
      <path
        d="M126.543 161.041L164.138 121.893L181.124 136.584L143.052 176.305C140.468 179.001 136.223 179.194 133.405 176.743L126.998 171.171C123.979 168.545 123.771 163.927 126.543 161.041Z"
        stroke="#996690"
        stroke-opacity="0.5"
        stroke-width="2"
      />
    </>
  );
  const blouse = (
    <>
      <path
        d="M149.5 141L182.5 157.5L216 141L224 157.5L216 173.892V258L184.5 264L156.148 259.14C152.308 258.481 149.5 255.151 149.5 251.255V141Z"
        fill="#CF99C5"
      />
      <path
        d="M150.5 142.618L182.053 158.394L182.497 158.616L182.942 158.397L215.539 142.342L222.888 157.499L215.101 173.453L215 173.661V173.892V257.173L184.491 262.984L156.317 258.154C152.957 257.578 150.5 254.664 150.5 251.255V142.618Z"
        stroke="#996690"
        stroke-opacity="0.5"
        stroke-width="2"
      />
    </>
  );
  const skirt = (
    <>
      <path
        d="M148.5 239H218.5L235 320H151.562C146.93 320 143.266 316.079 143.58 311.458L148.5 239Z"
        fill="#CF99C5"
      />
      <path
        d="M144.578 311.526L149.434 240H217.683L233.776 319H151.562C147.509 319 144.303 315.569 144.578 311.526Z"
        stroke="#996690"
        stroke-opacity="0.5"
        stroke-width="2"
      />
    </>
  );

  return (
    <svg
      width="340"
      height="490"
      viewBox="90 45 180 360"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_30_261)">
        {/* <rect width="360" height="450" fill="white" /> */}
        {/* <rect width="360" height="450" fill="#D9D9D9"/> */}
        {/* <rect
          x="58.5"
          y="28.5"
          width="243"
          height="393"
          rx="11.5"
          fill="white"
          stroke="#727272"
          stroke-width="3"
          stroke-dasharray="6 6"
        /> */}
        <circle
          cx="184.649"
          cy="92.7805"
          r="32.3461"
          fill="#DFDADE"
          stroke="white"
          stroke-width="2"
        />
        <rect
          x="180.885"
          y="273.081"
          width="34.7686"
          height="113.559"
          rx="4"
          transform="rotate(-9.34159 180.885 273.081)"
          fill="#DFDADE"
          stroke="white"
          stroke-width="2"
        />
        <rect
          x="148.034"
          y="272.454"
          width="35.6154"
          height="113.981"
          rx="4"
          fill="#DFDADE"
          stroke="white"
          stroke-width="2"
        />
        <rect
          x="135.74"
          y="181.416"
          width="18.0349"
          height="70.6572"
          rx="6"
          transform="rotate(-114.139 135.74 181.416)"
          fill="#DFDADE"
          stroke="white"
          stroke-width="2"
        />
        <rect
          x="247.789"
          y="233.328"
          width="23.0106"
          height="93.2374"
          rx="6"
          transform="rotate(161.359 247.789 233.328)"
          fill="#DFDADE"
          stroke="white"
          stroke-width="2"
        />
        <path
          d="M214.374 262.939L215.374 262.954L215.389 261.954L217.133 146.156C217.183 142.842 214.537 140.116 211.224 140.066L160.306 139.3C156.993 139.25 154.267 141.895 154.217 145.208L152.473 261.007L152.458 262.006L153.458 262.022L214.374 262.939Z"
          fill="#DFDADE"
          stroke="white"
          stroke-width="2"
        />
        {props.selectedId === "marškinėliai" && tShirt}
        {props.selectedId === "suknelė" && dress}
        {props.selectedId === "megztinis" && jacket1}
        {props.selectedId === "paltas" && coat1}
        {props.selectedId === "marškiniai" && shirt1}
        {props.selectedId === "palaidinė" && blouse}
        <rect
          x="171.525"
          y="85.395"
          width="32.3205"
          height="56.8352"
          rx="1"
          transform="rotate(8.92342 171.525 85.395)"
          fill="#AEAEAE"
          stroke="white"
          stroke-width="2"
        />
        <rect
          x="188.203"
          y="90.0383"
          width="12.2944"
          height="12.2944"
          rx="1"
          transform="rotate(8.92342 188.203 90.0383)"
          fill="#AEAEAE"
          stroke="white"
          stroke-width="2"
        />
        <circle
          cx="196.102"
          cy="95.4162"
          r="2.71633"
          transform="rotate(9.07958 196.102 95.4162)"
          fill="white"
        />
        <circle
          cx="195.16"
          cy="100.416"
          r="1.37923"
          transform="rotate(9.07958 195.16 100.416)"
          fill="white"
        />
        {props.selectedId === "megztinis" && jacket2}
        {props.selectedId === "paltas" && coat2}
        {props.selectedId === "marškiniai" && shirt2}
        <rect
          x="176.335"
          y="107.294"
          width="23.0106"
          height="80.6883"
          rx="6"
          transform="rotate(43.7446 176.335 107.294)"
          fill="#DFDADE"
          stroke="white"
          stroke-width="2"
        />
        {props.selectedId === "kelnės" && trousers}
        {props.selectedId === "šortai" && shorts}
        {props.selectedId === "megztinis" && jacket3}
        {props.selectedId === "paltas" && coat3}
        {props.selectedId === "marškinai" && shirt3}
        {props.selectedId === "sijonas" && skirt}
      </g>
      <defs>
        <clipPath id="clip0_30_261">
          <rect width="360" height="450" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FilmImage;
