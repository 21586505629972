import React from "react";
import UserInput from "./userInput";
import { Client as Styletron } from "styletron-engine-monolithic";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider, styled, createTheme } from "baseui";
import Landing from "./Landing";
import LandingPage from "./landing/LandingPage";
import PolicyPage from "./policy/PolicyPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const engine = new Styletron();

const primitives = {
  primary: "#963484",
  accent: "#963484", // hot pink
  accent50: "#FDEDFC",
  accent100: "#FCD3F9",
  accent200: "#F89FF3",
  accent300: "#F45AEA",
  accent400: "#F127E4",
  accent500: "#B71DAD",
  accent600: "#901788",
  accent700: "#600F5B",
};

const theme = createTheme(primitives);

const Centered = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
});
function App() {
  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={theme}>
        <Centered>
          <Router>
            <Routes>
              <Route index path="/" element={<LandingPage />} />
              <Route path="*" element={<LandingPage />} />
              <Route path="privatumo-politika" element={<PolicyPage />} />
            </Routes>
          </Router>
        </Centered>
      </BaseProvider>
    </StyletronProvider>
  );
}

export default App;
