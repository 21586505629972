import { ImperativeMethods, Select, SIZE } from "baseui/select";
import { HeadingLarge } from "baseui/typography";
import React from "react";
import { useStyletron } from "styletron-react";

const SelectClothing = (props: {
  clothingValue: any;
  setClothingValue: Function;
  errorMsg: string | null;
  setErrorMsg: Function;
}) => {
  const [css] = useStyletron();
  const controlRef = React.useRef<ImperativeMethods>(null);
  return (
    <div>
      <HeadingLarge style={{ marginBottom: 15, color: "#963484" }}>
        Pasirinkite drabužį:
      </HeadingLarge>
      <div>
        <Select
          size={SIZE.large}
          error={props.errorMsg != null}
          clearable={false}
          controlRef={controlRef}
          closeOnSelect={true}
          onBlurResetsInput={false}
          placeholder="Dabar matuojuosi..."
          options={[
            { id: "marškinėliai", label: "marškinėliai" },
            { id: "kelnės", label: "kelnės" },
            { id: "suknelė", label: "suknelė" },
            { id: "sijonas", label: "sijonas" },
            { id: "palaidinė", label: "palaidinė" },
            { id: "marškiniai", label: "marškiniai" },
            { id: "megztinis", label: "megztinis" },
            { id: "paltas", label: "paltas" },
            { id: "šortai", label: "šortai" },
            { id: "švarkas", label: "švarkas" },
            { id: "striukė", label: "striukė" },
          ]}
          value={props.clothingValue.id == "none" ? [] : [props.clothingValue]}
          onOpen={() => controlRef.current?.blur()}
          onChange={({ value }) => {
            controlRef.current?.blur();
            props.setErrorMsg(null);
            props.setClothingValue(value[0]);
          }}
          overrides={{
            Root: {
              style: { borderColor: "#963484", width: "340px" },
            },

            Placeholder: {
              style: { color: "black" },
            },
            SingleValue: {
              style: { color: "black" },
            },
            DropdownListItem: {
              style: { fontSize: "17px" },
            },
            DropdownOption: {
              style: {
                fontSize: "17px",
                backgroundColor: "RGBA(255,255,255,0.5)",
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default SelectClothing;
