export const ALLOWED_FILE_FORMATS = ["jpg", "jpeg", "png", "heif", "heic"];
// Change this to true to use mock data
export const DO_MOCK = false;
export const MOCK_DATA = {
  score: "Great fit",
  TrsContent: "Jums tinka šis drabužis",
};

export const FeedbackTitlePlaceholder = "Žinutė";
export const FeedbackSummaryPlaceholder = "Aprašymas";
