import { DisplaySmall, HeadingSmall } from "baseui/typography";
import React from "react";

const Header = () => {
  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          width: "100%",
          backgroundImage: "url('headerImg.png')",
          backgroundSize: "cover",
          backgroundPosition: "top",
          height: "50vh",
        }}
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            background:
              "linear-gradient(rgba(0,0,0,0.21),rgba(255,255,255,0.50),rgba(255,255,255,1))",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100vw",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Header;
