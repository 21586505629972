import exp from "constants";
import React from "react";

const Doc = () => {
  return (
    <div style={{ marginLeft: "20px", marginRight: "20px" }}>
      <h1>armantinka.lt naudojimo taisyklės</h1>

      <section>
        <p>Įsigaliojimo data: 2024-09-18</p>
        <h2>Apžvalga</h2>
        <p>
          Sveiki atvykę į armantinka.lt, interneto svetainę, skirtą vertinti
          aprangą įkeliant nuotrauką.
        </p>
        <p>
          Naudodamiesi armantinka.lt, jūs sutinkate su svetainės naudojimo
          taisyklėmis.
        </p>
      </section>

      <section>
        <h2>Svetainės naudojimo taisyklės</h2>
        <h3>Įprastas naudojimas - ką daryti ir ko nedaryti</h3>
        <p>
          <strong>Ką daryti:</strong>
        </p>
        <ul>
          <li>
            Kelkite aiškią nuotrauką, neuždengiant tikrinamo aprangos elemento.
          </li>
          <li>Laikykite telefoną ar kamerą vertikaliai.</li>
        </ul>
        <p>
          <strong>Ko nedaryti:</strong>
        </p>
        <ul>
          <li>
            Kelti nuotraukas, kurios nėra susijusios su apranga ar kitus failus.
          </li>
          <li>kelti asmenų iki 13 metų nuotraukas.</li>
          <li>kelti nuotraukas, kurios gali būti laikomos nepadoriomis.</li>
        </ul>
      </section>
      <section>
        <h3>Tai nemokama svetainė, tačiau...</h3>
        <p>
          ...pasiliekame teisę nesuteikti šioje svetainėje teikiamų paslaugų tam
          tikrais atvejais:
        </p>
        <ul>
          <li>Pastebėjus svetainės naudojimą ne pagal paskirtį.</li>
          <li>
            Pastebėjus intelektualinės nuosavybės savinimosi ar kitokius
            svetainei nepalankių veiksmų atvejus.
          </li>
          <li>Pastebėjus robotizuoto naudojimo atvejus.</li>
          <li>
            Pastebėjus naudojimą ne per arvertapirkti.lt svetainę ar su ja
            susijusius domenus.
          </li>
          <li>Kylant įtarimų dėl kitų svetainės taisyklių pažeidimų.</li>
        </ul>
      </section>
      <section>
        <h3>Duomenų apsauga:</h3>
        <p>
          svetainė kaupia ir naudoja tik tuos duomenis, kurie yra būtini
          svetainės veikimui ir tobulinimui. Apie tai, kaip naudojami jūsų
          duomenys aprašyta privatumo politikoje.
        </p>
      </section>

      <h1>armantinka.lt Privatumo Politika</h1>

      <section>
        <p>Įsigaliojimo data: 2024-09-18</p>
        <h2>Apžvalga</h2>
        <p>
          Sveiki atvykę į armantinka.lt, interneto svetainę, skirtą vertinti
          aprangą įkeliant nuotrauką. Mes esame įsipareigoję saugoti jūsų
          privatumą. Ši Privatumo Politika yra sukurta tam, kad padėtų jums
          suprasti, kaip mes renkame, naudojame ir saugome jūsų asmeninę
          informaciją.
        </p>
        <p>
          Naudodamiesi armantinka.lt, jūs sutinkate su šioje Privatumo
          Politikoje nurodytomis sąlygomis.
        </p>
      </section>

      <section>
        <h2>Informacija, kurią renkame</h2>
        <h3>Asmeninė informacija</h3>
        <p>
          Siekdami suteikti jums geriausią įmanomą patirtį, armantinka.lt gali
          rinkti šiuos asmeninės informacijos tipus:
        </p>
        <ul>
          <li>
            <strong>Kontaktinė informacija:</strong> Mes galime rinkti jūsų el.
            pašto adresą ar kitą kontaktinę informaciją.
          </li>
          <li>
            <strong>Vartotojo pateikti duomenys:</strong> Mes galime rinkti
            duomenis, kuriuos pateikiate įkeldami nuotrauką.
          </li>
        </ul>

        <h3>Naudojimo informacija</h3>
        <p>
          Mes taip pat galime rinkti neasmeninę informaciją apie tai, kaip jūs
          sąveikaujate su armantinka.lt. Ši informacija gali apimti:
        </p>
        <ul>
          <li>Įrenginio tipas</li>
          <li>IP adresas</li>
          <li>Naršyklės tipas</li>
          <li>Naudojimo modeliai</li>
        </ul>
      </section>
      <section>
        <h2>Kaip mes naudojame jūsų informaciją</h2>
        <p>
          Mes galime naudoti jūsų asmeninę informaciją, kad:
          <ul>
            <li>Suteiktume jums geriausią įmanomą paslaugą</li>
            <li>Tobulintume savo programėlę</li>
            <li>Atsakytume į jūsų užklausas</li>
            <li>Siųstume jums rinkodaros pranešimus</li>
            <li>Užkirstume kelią sukčiavimui</li>
            <li>Vykdytume teisines prievoles</li>
          </ul>
        </p>
      </section>
      <section>
        <h2>Informacijos dalijimasis</h2>
        <p>
          armantinka.lt neparduoda, neprekiauja ir nenuomoja jūsų asmeninės
          informacijos tretiesiems asmenims. Mes galime dalytis jūsų informacija
          su patikimais trečiųjų šalių paslaugų teikėjais, kurie padeda mums
          valdyti mūsų programėlę, vykdyti mūsų veiklą ar teikti jums paslaugas.
        </p>
      </section>
      <section>
        <h2>Saugumas</h2>
        <p>
          Mes imamės priemonių, kad apsaugotume surinktą informaciją nuo
          neteisėtos prieigos, atskleidimo, pakeitimo ar sunaikinimo. Tačiau
          joks perdavimo internetu ar elektroninio saugojimo metodas nėra
          visiškai saugus, ir mes negalime garantuoti absoliutaus saugumo.
        </p>
      </section>
      <section>
        <h2>Jūsų pasirinkimai</h2>
        <p>Jūs turite teisę:</p>
        <ul>
          <li>Prieiti, atnaujinti ar ištrinti savo informaciją</li>
          <li>Prieštarauti jūsų informacijos rinkimui ir naudojimui</li>
        </ul>
      </section>
      <section>
        <h2>Vaikų privatumas</h2>
        <p>
          armantinka.lt nėra skirta vaikams iki 13 metų amžiaus. Mes sąmoningai
          nerenkame asmeninės informacijos iš vaikų iki 13 metų amžiaus.
        </p>
      </section>
      <section>
        <h2>Privatumo politikos pakeitimai</h2>
        <p>
          armantinka.lt pasilieka teisę bet kada keisti šią Privatumo Politiką.
          Mes skatiname jus dažnai peržiūrėti šią Privatumo Politiką dėl bet
          kokių pakeitimų. Šios Privatumo Politikos pakeitimai įsigalioja, kai
          jie yra paskelbiami šiame puslapyje.
        </p>
        <p>Atsiradus pakeitimų, iš naujo prašysime jūsų sutikimo.</p>
      </section>
      <section>
        <h2>Susisiekite su mumis</h2>
        <p>
          Jei turite kokių nors klausimų apie šią Privatumo Politiką, prašome
          susisiekti su mumis pagrindiame svetainės puslapyje.
        </p>
      </section>
    </div>
  );
};

export default Doc;
