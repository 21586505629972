import React from "react";
import Header from "./uiComps/Head";
import Doc from "./uiComps/Doc";

const PolicyPage = () => {
  return (
    <div>
      <Header />

      <Doc />
    </div>
  );
};

export default PolicyPage;
